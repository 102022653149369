<template>
  <q-page class="notifications">
    <div class="title">
      Centre de notifications
    </div>
      <div class="content-container">
        <q-table
          class="table"
          :data="tableData"
          :columns="columns"
          separator="cell"
          rowKey="id"
          :pagination.sync="pagination"
          :filter="filter"
        >
          <template slot="top-right">
            <q-search
              v-model="filter"
              color="tertiary"
            />
          </template>
          <template slot="top-left">
            <!-- type selection -->
            <q-select
              v-model="selectedType"
              separator
              color="tertiary"
              class="table-control"
              floatLabel="Filtrer par type"
              :options="[
                { label: 'Tout', value: 'all' },
                { label: 'Notification', value: 'notification' },
                { label: 'Alert', value: 'alert' },
                { label: 'Ticket', value: 'ticket' }
              ]"
              hideUnderline
            />
            <!-- time selection -->
            <q-select
              v-model="selectedTime"
              separator
              color="tertiary"
              class="table-control"
              floatLabel="Filtrer par Date"
              :options="[
                { label: `Tout`, value: 'all' },
                { label: `Aujourd'hui`, value: 'day' },
                { label: 'Cette semaine', value: 'week' },
                { label: 'Ce mois ci', value: 'month' },
                { label: 'Cette année', value: 'year' }
              ]"
              hideUnderline
            />
            <q-toggle v-model="includeHistory"
                    class="table-control"
                    label="Notifications lues"
                    color="blue-grey-3"
                    leftLabel
                    keepColor />
            <q-btn icon="far fa-envelope-open"
                   :loading="readAllLoading"
                   label="Tout marquer comme lu"
                   size="sm"
                   outline
                   @click="readAll()" />
          </template>

          <!-- slot name syntax: body-cell-<column_name> -->
          <!-- view -->
          <q-td slot="body-cell-view"
                slot-scope="props"
                class="zero-width pointer"
                :style="props.value ? 'background-color: #dfdfdf': ''"
                :props="props"
                :title="props.value ? 'Marquer comme non lu' : 'Marquer comme lu'"
                @click.native="setNotificationReadStatus(props.row.id, !props.value)">
            <q-spinner v-if="props.row.spinner"
                      color="secondary"
                      :size="15" />
            <q-icon v-else-if="props.value"
                    class="far fa-envelope-open notification-icon"/>
            <q-icon v-else
                    class="far fa-envelope notification-icon"/>
          </q-td>
          <!-- message -->
          <q-td slot="body-cell-message"
                slot-scope="props"
                :props="props">
                <strong>{{props.value}}</strong>
          </q-td>
          <!-- type -->
          <q-td slot="body-cell-type"
                slot-scope="props"
                class="zero-width"
                :title="props.value.name"
                :props="props">
            <q-icon class="notification-icon"
                :class="props.value.class"/>
          </q-td>
          <!-- link -->
          <q-td slot="body-cell-link"
                slot-scope="props"
                class="pointer zero-width"
                :props="props"
                @click.native="navigate(props.value)">
            <q-icon class="link-details fas fa-arrow-right"/>
          </q-td>
        </q-table>
      </div>
    </q-page>
</template>

<script>

import api from '@/services/api';
import moment from 'moment';

export default {
  name: 'Notifications',
  data() {
    return {
      pagination: {
        sortBy: 'datetime',
        descending: true,
        page: 1,
        rowsPerPage: 10
      },
      filter: '',
      readAllLoading: false,
      includeHistory: false,
      selectedType: 'all',
      selectedTime: 'all',
      columns: [
        {
          name: 'view',
          required: false,
          label: 'Lu',
          align: 'center',
          field: 'view',
        },
        {
          name: 'message',
          required: true,
          label: 'Message',
          align: 'left',
          field: 'message',
          sortable: true,
        },
        {
          name: 'type',
          required: true,
          label: 'Type',
          align: 'center',
          field: 'type',
        },
        {
          name:'datetime',
          label:'Date',
          field: 'datetime',
          align: 'left',
          required: true,
          sortable: true,
          format: val => this.formatTime(val),
          style: 'width: 0',
        },
        {
          name:'link',
          label:'Détails',
          field: 'link',
          align: 'right',
        },
      ],
      notificationIdsWithSpinnerSet: new Set(),
    };
  },
  computed: {
    tableData() {
      let filteredNotifs = [...this.$store.state.notification.collection];
      // remove read notif
      if (!this.includeHistory)
        filteredNotifs = filteredNotifs.filter(notif => !notif.dateRead);
      switch(this.selectedType) {
        case 'all':
          // do nothing
          break;
        case 'ticket':
          filteredNotifs = filteredNotifs.filter(notif => notif.ticketId);
          break;
        case 'alert':
          filteredNotifs = filteredNotifs.filter(notif => notif.alertId);
          break;
        case 'notification':
          filteredNotifs = filteredNotifs.filter(notif => !notif.alertId && !notif.ticketId);
          break;
        default:
          break;
      }
      switch(this.selectedTime) {
        case 'day':
          filteredNotifs = filteredNotifs.filter(notification =>
            moment(notification.createdAt).format('DD-MM-YYYY') === (this.today).format('DD-MM-YYYY')
          );
          break;
        case 'week':
          filteredNotifs = filteredNotifs.filter(notification =>
            moment(notification.createdAt).week() === this.today.week()
          );
          break;
        case 'month':
          filteredNotifs = filteredNotifs.filter(notification =>
            moment(notification.createdAt).month() === this.today.month()
          );
          break;
        case 'year':
          filteredNotifs = filteredNotifs.filter(notification =>
            moment(notification.createdAt).year() === this.today.year()
          );
          break;
        case 'all':
        default:
          // do nothing
          break;
      }
      return filteredNotifs.map(notif => {
        return {
          view: !!notif.dateRead,
          message: notif.title,
          type: this.notificationType(notif),
          datetime: notif.dateCreated,
          link: notif,
          id: notif.id,
          spinner: this.notificationIdsWithSpinnerSet.has(notif.id),
        };
      });
    },
    today() {
      return moment();
    },
  },
  async created() {
    if (!this.$store.state.notification.collection.length)
      await this.$store.dispatch('notification/fetch');
  },
  methods: {
    notificationType(notif) {
      if (notif.ticketId) {
        return {
          name: 'Ticket',
          class:{
            'far fa-sticky-note': true
          }
        };
      }
      if (notif.alertId) {
        return {
          name: 'Alerte',
          class:{
            'fas fa-exclamation-triangle': true,
          }
        };
      }
      return {
        name: 'Notification',
        class:{
          'far fa-comment-alt': true
        }
      };
    },
    formatTime(date) {
      const now = moment().locale(this.$i18n.locale);
      const from = moment(date);

      const differenceInSeconds = now.diff(from, 'seconds');
      if (differenceInSeconds < 60 * 60 * 24)
        return from.fromNow();
      if (differenceInSeconds < 60 * 60 * 24 * 7)
        return `${from.fromNow()} (${from.format('DD-MM-YYYY - HH:mm')})`;
      return `${from.fromNow()} (${from.format('DD-MM-YYYY')})`;
    },
    async setNotificationReadStatus(notificationId, readStatus) {
      this.setSpinnerForNotifById(notificationId);
      try {
        await this.$store.dispatch('notification/setReadStatusById', { notificationId, readStatus });
      } catch {
        // TODO show error
      }
      this.unsetSpinnerForNotifById(notificationId);
    },
    async readAll() {
      this.readAllLoading = true;
      const { updatedCount } = await this.$store.dispatch('notification/setReadStatusForAll', { readStatus: true });
      this.readAllLoading = false;
      if (updatedCount) {
        let message;
        if (updatedCount === 1)
          message = `une notification marquée comme lue`;
        else
          message = `${updatedCount} notifications marquées comme lues`;
        this.$q.notify({
          message: message,
          type: 'positive',
          position: 'bottom-right',
        });
      }
    },
    navigate(notification) {
      if (!notification.dateRead) {
        this.setNotificationReadStatus(notification.id, true);
      }
      if (notification.alertId) {
        // go to alert details page
        this.$router.push({
          name: 'alertDetails',
          params: {
            alertId: notification.alertId,
          }
        });
      } else if (notification.ticket) {
        // go to ticket details page
        this.$router.push({
          name: 'public-ticket',
          params: {
            shortId: notification.ticket.shortId,
          }
        });
      } else if (notification.actionLink) {
        // open action link in new tab
        window.open(`${notification.actionLink}`, '_blank');
      } else {
        this.$q.notify({
          message: this.$t('pages.notification.actionLink.error'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    // kind of hacky, but Vue can't detect changes on Set if they're not re-assigned
    setSpinnerForNotifById(notificationId) {
      this.notificationIdsWithSpinnerSet = new Set(this.notificationIdsWithSpinnerSet.add(notificationId));
    },
    // kind of hacky, but Vue can't detect changes on Set if they're not re-assigned
    unsetSpinnerForNotifById(notificationId) {
      this.notificationIdsWithSpinnerSet.delete(notificationId);
      this.notificationIdsWithSpinnerSet = new Set(this.notificationIdsWithSpinnerSet);
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

.table
  height fit-content
  background-color $dash-light
  border-radius 3px
  box-shadow none
  .zero-width
    width 0

.notification-icon
  font-size 16px
.pointer
  cursor pointer
.table-control
  margin-right 20px
</style>
