var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{staticClass:"notifications"},[_c('div',{staticClass:"title"},[_vm._v(" Centre de notifications ")]),_c('div',{staticClass:"content-container"},[_c('q-table',{staticClass:"table",attrs:{"data":_vm.tableData,"columns":_vm.columns,"separator":"cell","rowKey":"id","pagination":_vm.pagination,"filter":_vm.filter},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body-cell-view",fn:function(props){return _c('q-td',{staticClass:"zero-width pointer",style:(props.value ? 'background-color: #dfdfdf': ''),attrs:{"props":props,"title":props.value ? 'Marquer comme non lu' : 'Marquer comme lu'},nativeOn:{"click":function($event){return _vm.setNotificationReadStatus(props.row.id, !props.value)}}},[(props.row.spinner)?_c('q-spinner',{attrs:{"color":"secondary","size":15}}):(props.value)?_c('q-icon',{staticClass:"far fa-envelope-open notification-icon"}):_c('q-icon',{staticClass:"far fa-envelope notification-icon"})],1)}},{key:"body-cell-message",fn:function(props){return _c('q-td',{attrs:{"props":props}},[_c('strong',[_vm._v(_vm._s(props.value))])])}},{key:"body-cell-type",fn:function(props){return _c('q-td',{staticClass:"zero-width",attrs:{"title":props.value.name,"props":props}},[_c('q-icon',{staticClass:"notification-icon",class:props.value.class})],1)}},{key:"body-cell-link",fn:function(props){return _c('q-td',{staticClass:"pointer zero-width",attrs:{"props":props},nativeOn:{"click":function($event){return _vm.navigate(props.value)}}},[_c('q-icon',{staticClass:"link-details fas fa-arrow-right"})],1)}}])},[_c('template',{slot:"top-right"},[_c('q-search',{attrs:{"color":"tertiary"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('template',{slot:"top-left"},[_c('q-select',{staticClass:"table-control",attrs:{"separator":"","color":"tertiary","floatLabel":"Filtrer par type","options":[
              { label: 'Tout', value: 'all' },
              { label: 'Notification', value: 'notification' },
              { label: 'Alert', value: 'alert' },
              { label: 'Ticket', value: 'ticket' }
            ],"hideUnderline":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('q-select',{staticClass:"table-control",attrs:{"separator":"","color":"tertiary","floatLabel":"Filtrer par Date","options":[
              { label: "Tout", value: 'all' },
              { label: "Aujourd'hui", value: 'day' },
              { label: 'Cette semaine', value: 'week' },
              { label: 'Ce mois ci', value: 'month' },
              { label: 'Cette année', value: 'year' }
            ],"hideUnderline":""},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}}),_c('q-toggle',{staticClass:"table-control",attrs:{"label":"Notifications lues","color":"blue-grey-3","leftLabel":"","keepColor":""},model:{value:(_vm.includeHistory),callback:function ($$v) {_vm.includeHistory=$$v},expression:"includeHistory"}}),_c('q-btn',{attrs:{"icon":"far fa-envelope-open","loading":_vm.readAllLoading,"label":"Tout marquer comme lu","size":"sm","outline":""},on:{"click":function($event){return _vm.readAll()}}})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }